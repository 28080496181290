<template>
    <div :class="{ homepage: key == '/bigScreen/index' }">
        <my-header @handleFoldMenus="handleFoldMenus"></my-header>
        <my-menus :isCollapse="isCollapse" v-if="showAll"></my-menus>
        <div :class="{ hideSidebar: isCollapse, main: showAll }">
            <div ref="iframe" class="iframe">
                <transition name="fade-transform" mode="out-in">
                    <router-view v-if="isVisible" :isCollapse="isCollapse" />
                </transition>
            </div>
            <div id="sub" v-show="$route.path != '/' && $route.path.indexOf('/vue') != -1"></div>
        </div>
    </div>
</template>
<script>
import MyHeader from '@/components/MyHeader.vue';
import MyMenus from '@/components/MyMenus.vue';

export default {
    name: 'Layout',
    components: {
        MyHeader,
        MyMenus
    },
    computed: {
        key() {
            return this.$route.path;
        }
    },
    data() {
        return {
            isCollapse: true,
            isVisible: true,
            showAll: true
        };
    },
    watch: {
        $route(to, from) {
            // 对路由变化作出响应...
            // console.log(to,from)
            if (to.name == from.name) {
                this.isVisible = false;
                setTimeout(() => {
                    this.isVisible = true;
                }, 200);
            }
        }
    },
    mounted() {
        this.showAll = localStorage.getItem('AUTH_TOKEN') != 'E7IpJc*6(=}<' ? true : false;
    },
    methods: {
        handleFoldMenus(isFold) {
            this.isCollapse = isFold;
        }
    },
    destroyed() {}
};
</script>
<style lang="less" scoped>
#sub {
    #container {
        .hideSidebar {
            left: 0;
        }
        .main {
            top: 0;
        }
    }
}
.wrap {
    min-height: 98%;
    position: relative;
}
.wrap.homepage {
    background-color: rgb(38, 45, 60);
}
.main {
    position: absolute;
    top: 0.8rem;
    bottom: 0;
    left: 200px;
    /* left:0; */
    right: 0;
    z-index: 10;
    background-color: #f2f8fc;
    /* padding-bottom: 0.3rem; */
    transition: all 0.6s;
}
.hideSidebar {
    left: 64px;
}
.iframe {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0.23rem 0.3rem 0.3rem;
}
.homepage .iframe {
    padding: 0;
}
/* 子应用容器 */
#sub {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 999;
    overflow: scroll;
    /* border: 1px solid red; */
}
</style>