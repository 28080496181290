<template>
    <div class="overflowTxt">
        <div ref="showTxt" class="hideTxt ellipsis">{{ txt }}</div>
        <el-tooltip v-if="isOverflow" effect="dark" :content="txt" placement="top-start">
            <div class="ellipsis">{{ txt ? txt : '' }}</div>
        </el-tooltip>
        <div class="ellipsis" v-else>{{ txt ? txt : '' }}</div>
    </div>
</template>
<script>
export default {
    props: {
        txt: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isOverflow: false
        };
    },
    mounted() {
        this.isOverflow = this.hasTextOverflowEllipsisAndOverflow(this.$refs.showTxt);
    },
    methods: {
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        txt: {
            handler(val) {
                if (val) {
                    setTimeout(() => {
                        this.isOverflow = this.hasTextOverflowEllipsisAndOverflow(this.$refs.showTxt);
                    }, 100);
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
.overflowTxt {
    width: 100%;
    .hideTxt {
        height: 0;
    }
}
// 超出隐藏 展示省略号
.ellipsis {
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 隐藏超出容器的文本 */
    text-overflow: ellipsis; /* 在文本末尾显示省略号 */
}
</style>