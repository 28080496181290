import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import "lib-flexible";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import * as echarts from "echarts";
import "./assets/css/responsive.css";
import "./assets/css/table_public.css";
// 导入qiankun.js
import { registerMicroApps, setDefaultMountApp, start } from "qiankun";
// 自定义组件-文本超出隐藏
import OverflowTxt from "./components/overflowTxt.vue";

import {
    getToken,
    intervalRefreshToken,
    setTimeToken,
    getTimeToken,
} from "./utils/auth.js";
// 全局注册组件
Vue.component("overflow-txt", OverflowTxt);
var _versions = store.state.version;

const upmsDomain = process.env.VUE_APP_UPMS_DOMAIN;
// const upmsDomain = "//upms.sigreal.cn";
// 全局注册组件
Vue.component("overflow-txt", OverflowTxt);

console.log(
    `%c子应用访问域名：${upmsDomain}`,
    "background:#409eff;color:#ffffff;padding:5px;border-radius:3px;font-size:14px;"
);
// 注册子应用
registerMicroApps([
    {
        name: "vue app", // 子应用名称
        // entry: '//localhost:8081',  // 子应用入口
        entry: upmsDomain, // 子应用入口
        container: "#sub", // 子应用所在容器
        activeRule: "/vue", // 子应用触发规则（路径）
    },
]);

// 启动默认应用
// setDefaultMountApp('/vue')

// 开启服务
start({
    prefetch: true, // 取消预加载
});

var baseURL = store.getters.baseURL;
Vue.prototype.role = "";
Vue.prototype.userInfo = "";
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.instance = Vue.prototype.$axios.create({
    baseURL,
});
//请求拦截器
Vue.prototype.instance.interceptors.request.use(
    (config) => {
        config.headers["Authorization"] = "Bearer " + getToken();
        config.headers["X-From-Business-Line"] = "jiaanan";
        config.headers["X-From-Tenant"] = "11300";
        localStorage.setItem("X-From-Business-Line", "jiaanan");
        localStorage.setItem("X-From-Tenant", "11300");
        //请求之前的操作  Authorization
        if (
            localStorage.getItem("AUTH_TOKEN") == "E7IpJc*6(=}<" ||
            location.pathname == "/calculatorTool/invoiceCheck"
        ) {
            config.headers.token = "E7IpJc*6(=}<";
        }

        var userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            if (config.method.toLowerCase() == "get") {
                config.params.company = userInfo.company;
                config.params.jobNo = userInfo.jobNo;
                config.params.role = userInfo.role;
            } else {
                config.data.company = userInfo.company;
                config.data.jobNo = userInfo.jobNo;
                config.data.role = userInfo.role;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//响应拦截器
Vue.prototype.instance.interceptors.response.use(
    (res) => {
        if (res.data.code == 401) {
            window.alert("抱歉，当前账号登录信息已过期，请重新登录");
            localStorage.clear();
            router.push({
                path: "/",
                // query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面
            });
            location.reload();
        }
        return res;
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    window.alert("抱歉，当前账号登录信息已过期，请重新登录");
                    localStorage.clear();
                    router.push({
                        path: "/",
                        // query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面
                    });
                    location.reload();
            }
        }
    }
);

Vue.config.productionTip = false;

// 埋点方法
/**
 * {caseNo:'',eventId:'',extFlag:'',extStr:'',extType:'',extVal:''}
 * caseNo 案件号  string
 * eventId 事件ID  string
 * remark 事件备注 string
 * extFlag 拓展值-布尔  boolean
 * extStr 拓展值-字符串  string
 * extType 拓展值类型  string
 * extVal 拓展值数字  number
 */
Vue.prototype.dataCollection = (option) => {
    Vue.prototype.instance
        .post("/user/usage/userOperation", option)
        .then((res) => {});
};

//定时刷新token
intervalRefreshToken();
//路由跳转前做判断
router.beforeEach((to, from, next) => {
    let AUTH_TOKEN = getToken(); //从sessionStorage中获取是否已登陆过的信息
    // if(to.meta.title){
    //   document.title = to.meta.title
    // }
    if (AUTH_TOKEN) {
        next();
    } else {
        // console.log(to);
        if (to.path == "/") {
            next();
            // next(`/Login?redirect=${to.fullPath}`)
        } else {
            // next(`/Login?redirect=${to.fullPath}`)
            next({
                replace: true,
                name: "Login",
            });
        }
    }
});
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
Vue.use(VueAxios, axios);
// Vue.use(BaiduMap, {
//   ak: 'KnZT8txcSxDHyz00qt2N7mCAzSkbGi5Y'
// })
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
var fontSize = 0;
var fontRem = 0;
// 获取 URL 参数
Vue.prototype.getQueryString = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var href = window.location.href;
    href = decodeURI(href);
    var r = href.substr(href.indexOf("?") + 1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
};
// 时间格式化
Vue.prototype.dateFormatting = function (date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
};
//保留两位小数并去掉末尾的0
Vue.prototype.unifyNumber = function (num) {
    if (num === "") {
        return 0;
    } else {
        let handleNum = parseFloat(num);
        let isToFixed =
            handleNum.toString().includes(".") &&
            handleNum.toString().split(".")[1].length > 2;
        if (isToFixed) {
            return handleNum.toFixed(2);
        } else {
            return handleNum;
        }
    }
};

Array.prototype.indexOf = function (val) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == val) return i;
    }
    return -1;
};
Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

//获取枚举信息
Vue.prototype.getPublicEnum = async function (dictType, keyName) {
    await this.instance
        .post("/backstage/getDict", { dictType: dictType })
        .then((res) => {
            let data = res.data;
            if (data.code == 1) {
                this[keyName] = data.data;
            }
        });
};
